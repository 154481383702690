@import 'tailwindcss/base';

@import 'tailwindcss/components';
@import './components.css';
/* overwrite Sendbird styles */
@import './chat.css';

@import 'tailwindcss/utilities';

body {
  @apply font-normal antialiased text-leland-gray-dark;
}

@layer components {
  .bottom-message-cta-hidden {
    @apply h-20;
    transform: translateY(calc(100%));
  }

  .bottom-message-cta-visible {
    @apply h-20;
    transform: translateY(calc(100% - 80px));
  }

  /* nprogress bar color overrides */
  #nprogress .bar {
    @apply bg-leland-primary h-1 !important;
  }

  #nprogress .peg {
    @apply shadow-md shadow-leland-primary !important;
  }

  #nprogress .spinner-icon {
    @apply border-leland-primary !important;
  }
}
