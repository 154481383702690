.sendbird-channel-list__header,
.sendbird-channel-preview__action,
.sendbird-chat-header,
.sendbird-message-content__left.incoming {
  display: none !important;
}

.sendbird-conversation {
  border: none !important;
  overflow: auto;
}

.sendbird-conversation .sendbird-text-message-item-body,
.sendbird-conversation .sendbird-og-message-item-body span,
.sendbird-conversation span,
.sendbird-conversation .sendbird-message-input--textarea,
.sendbird-label {
  font-family: 'Calibre' !important;
}
.sendbird-conversation__messages {
  flex: 1 1 auto;
}
.sendbird-conversation__messages-padding {
  display: flex;
  flex-direction: column;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  .sendbird-message-hoc__message-content.outgoing,
  .sendbird-message-hoc__message-content.incoming {
    height: fit-content;
    .sendbird-message-content__middle {
      max-width: 80%;
      .sendbird-thumbnail-message-item-body.outgoing,
      .sendbird-thumbnail-message-item-body.incoming,
      .sendbird-thumbnail-message-item-body__thumbnail {
        max-width: 17rem !important;
        min-width: 17rem !important;
        height: 17rem !important;
        .sendbird-image-renderer__image {
          max-width: 17rem !important;
          min-width: 17rem !important;
          height: 17rem !important;
          background-size: contain !important;
        }
      }
      .sendbird-og-message-item-body {
        max-width: 17rem !important;
        min-width: 17rem !important;
      }
    }
  }
}

.sendbird-conversation__messages-padding > :first-child {
  @apply mt-auto;
}

.sendbird-message-content-reactions {
  @apply border border-t-0 !bg-transparent;
  .sendbird-emoji-reactions {
    @apply items-center space-x-2 !border-none;
  }
}

.sendbird-emoji-reactions__reaction-badge {
  @apply !mb-0;
}

.sendbird-message-content.outgoing:hover {
  .sendbird-text-message-item-body.outgoing,
  .sendbird-og-message-item-body.outgoing
    .sendbird-og-message-item-body__text-bubble {
    background-color: var(--sendbird-light-background-100) !important;
  }
}

.sendbird-text-message-item-body.outgoing,
.sendbird-og-message-item-body.outgoing
  .sendbird-og-message-item-body__text-bubble {
  color: var(--sendbird-light-onlight-01) !important;
  border: 1px solid var(--sendbird-light-background-200);
  background-color: white !important;
}

.sendbird-og-message-item-body.outgoing {
  .sendbird-og-message-item-body__og-thumbnail,
  .sendbird-og-message-item-body__description {
    border: 1px solid var(--sendbird-light-background-200);
  }
}

.sendbird-og-message-item-body.outgoing
  .sendbird-og-message-item-body__og-thumbnail {
  border-top: none;
  border-bottom: none;
}

.sendbird-link-label,
.sendbird-link-label__label {
  @apply text-leland-primary !important;
}

.sendbird-conversation__footer {
  @apply shrink-0;
}
