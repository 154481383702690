/* TODO: Investigate moving these to tailwind configuration */

@layer components {
  .button {
    @apply p-3 rounded-lg leading-tight cursor-pointer hover:bg-gray-100 font-medium;
  }

  .button--disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  .button:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  .input,
  #stripe-card-element {
    @apply px-4 py-3 rounded-lg border border-gray-200 box-border;
  }
  .input:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  .link {
    @apply hover:underline underline-offset-2;
  }

  .link--primary {
    @apply text-leland-primary;
  }

  .form-radio {
    @apply border-leland-gray-stroke rounded-full checked:text-leland-primary checked:border-leland-primary !important;
  }

  .form-textarea:focus,
  .form-select:focus,
  .form-multiselect:focus,
  .form-radio:focus {
    @apply border-leland-gray-stroke outline-0 outline-none shadow-none ring-0 !important;
  }

  .form-input:focus {
    @apply transition duration-100 border-leland-gray-extra-light outline-0 outline-none shadow-none ring-0 !important;
  }
}
